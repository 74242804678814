<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon flaticon-bus-stop"></i>
                <h3 class="wizard-title">Contact Details</h3> 
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-list"></i>
                <h3 class="wizard-title">Restaurant type</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title">Menu</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-profile-1"></i>
                <h3 class="wizard-title">Social Links</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-symbol"></i>
                <h3 class="wizard-title">Branding</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-time-2"></i>
                <h3 class="wizard-title">Opening Timings</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-coins"></i>
                <h3 class="wizard-title">Delivery Charges</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-time"></i>
                <h3 class="wizard-title">Delivery / Collection Timings</h3>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
          <div class="col-xl-12 col-xxl-12">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current">
                <ContactInfo @validation="formValidation" ref="step_1"></ContactInfo>
              </div>
              <!--end: Wizard Step 1-->
              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <RestaurantType @validation="formValidation" ref="step_2"></RestaurantType>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <Menu @validation="formValidation" ref="step_3"></Menu>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <Social @validation="formValidation" ref="step_4"></Social>
              </div>
              <!--end: Wizard Step 4-->
                <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
              <Branding @validation="formValidation" ref="step_5"></Branding>
              </div>
            <div class="pb-5" data-wizard-type="step-content">
                <OpeningTimings @validation="formValidation" ref="step_6"></OpeningTimings>
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                <DeliveryCharge @validation="formValidation" ref="step_7"></DeliveryCharge>
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                <DeliveryCollectionTimeInfo @validation="formValidation" ref="step_8"></DeliveryCollectionTimeInfo>
              </div>

          <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <!-- <button
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Submit
                  </button> -->
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Next Step
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ContactInfo from "@/view/pages/business/components/ContactInfo";
import Branding from "@/view/pages/business/components/Branding";
import Menu from "@/view/pages/business/components/Menu";
import Social from "@/view/pages/business/components/Social";
import RestaurantType from "@/view/pages/business/components/RestaurantType";
import OpeningTimings from "@/view/pages/business/components/OpeningTimes";
import DeliveryCharge from "@/view/pages/business/components/DeliveryCharge";
import DeliveryCollectionTimeInfo from "@/view/pages/business/components/DeliveryCollectionTimeInfo";

export default {
  name: "Wizard",
  data() {
    return {
      formValid: false,
      wizObj: '',
    };
  },
  components: {
    ContactInfo,
    Branding,
    Menu,
    Social,
    RestaurantType,
    OpeningTimings,
    DeliveryCharge,
    DeliveryCollectionTimeInfo,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Wizard" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // console.log(wizard);
    // Validation before going to next page
    wizard.on("beforeNext", (wizardObj) => {
      this.wizObj = wizardObj;
      // validate the form and use below function to stop the wizard's step
      wizardObj.stop();
      let step = 'step_'+wizardObj.currentStep;
      this.$refs[step].onSubmit();
    });

    // Change event
    wizard.on("change", function(wizardObj) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      this.$refs['step_5'].onSubmit();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-primary"
      });
    },
    nextStep() {
      this.$refs['step_'+this.wizObj.currentStep].onSubmit();
    },
    formValidation(isValid) {
      if(isValid){
        this.wizObj.goNext();
      }
      else {
        Swal.fire({
        title: "Error",
        text: "Something is wrong",
        icon: "danger",
        confirmButtonClass: "btn btn-primary"
      });
      }
    }
  }
};
</script>
